
import _requestHelper from '@/apiManager/_requestHelper';
import InitLoader from '@/components/UI/Loader/InitLoader.vue';
import StoreService from '@/services/storeService';
import Vue from 'vue'
import { mapActions, mapState } from 'vuex';
export default Vue.extend({
    components: { 
        "init-loader": InitLoader 
    },    
    
    computed: {
        ...mapState("partner", ["partnerSettings"]),
        ...mapState("store", ["listOfStores"]),
    },
    
    methods: {
        ...mapActions("schedule", ["getStoreHomePageFirstAvailableSlots"]),
    },

    async mounted() {
        // get list of stores
        await StoreService.getElligibleStores();

        var storeToUse = this.listOfStores[0];

        // get store details
        await StoreService.getStoreDetails(storeToUse.id);

        // get store first available slots
        await this.getStoreHomePageFirstAvailableSlots();

        // if the deep link is a product
        // redirect to the item details
        if(this.$route.name === "ProductDeepLink") {
            this.$router.push({
                name: "productDetailsPage",
                params: {

                    productId: this.$route.params["productId"], 
                    productName: this.$route.params["productName"],
                    storeId: storeToUse.id.toString(),
                    storeDisplayName: storeToUse.displayName,
                    showWelcomeToast: "true"
                },

            })
        }
        
        else if(this.$route.name === "StoreHomePageDeepLink") {
            console.log(storeToUse);
            this.$router.push({
                name: "storeHomePage",
                params: {
                    storeId: storeToUse.id,
                    storeDisplayName: storeToUse.displayName
                },

            })
        }
    }
})
